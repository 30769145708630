@font-face {
  font-family: 'Quicksand';
  src: url('../public/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
}
* {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}
.fade {
  width: 80%;
  margin: 0 auto;
}
.divider {
  border-top: 1.5px solid hsla(51, 3%, 47%, 0.18);
  padding-top: 1.75em;
  margin: 0 auto;
}
.dividerHr {
  border-left: 1.5px solid hsla(50,3%,47%,.25);
  height: 90px; 
  width: 0;
  margin: 20px auto;
}
a {
  color: var(--link-color);
  text-decoration: none;
}
h1 {
  font-size: 3.125rem;
  line-height: 122%;
  font-weight: 500;
}
h2 {
  font-size: 25px;
  font-weight: 500;
}
h3 {
  font-weight: 400;
}
h4 {
  font-weight: 500;
}
body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}
p, .op-margin {
  display: block;
  margin: 1em 0;
  font-weight: 300;
  font-size: 17px;
}
p:not(.card-overlay p) {
  transition: transform 0.3s ease-in-out;
}
p:not(.card-overlay p):hover {
  transform: scale(1.03); 
}
#stay-tuned {
  text-align: center;
  font-size: 30px;
  /* color: hsla(50,3%,47%,.8); */
}
.green {
  /* color: #6a9b8f;  */
  font-weight: 500;
}
.highlight {
  font-weight: 500;
  animation: fadeIn 1.5s ease-in-out 1; 
  color: #6a9b8f; 
}
.highlight2 {
  font-weight: 500;
  color: rgb(74, 164, 209); 
  animation: fadeIn 1.5s ease-in-out 1; 
}
.highlight3 {
  font-weight: 500;
  color: #6a9b8f; 
}
.small-paragraph {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 0;
}
section {
  margin-bottom: 2em;
}
.flex {
  display: flex;
  flex-direction: row;
  gap: 6rem;
}
#titles {
  color: #6a9b8f;
  padding-bottom: 1em;
}
.left {
  flex: 4;
}
.right {
  flex: 2; 
}

/* NAVIGATION BAR */
.nav-container {
  display: flex;
  height: 4em;
  justify-content: space-between;
  padding-top: 2em;
  width: 80%;
  margin: 0 auto;
}
.custom-logo {
  width: 40px;
  height: 40px;
  margin-right: 2em;
}
.navbar a:hover {
  color: #6a9b8f;
}
.navbar .nav-container ul {
  /* grid-gap: 3rem; */
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4,minmax(0,1.5fr));
  line-height: 350%;
  list-style: none;
  margin-right: 0;
  padding: 0;
  text-align: center;
  z-index: 2;
}
.dropdown-item {
  display: block;
}
.theme-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin-left: 2em;
}

/* SIDE BAR */
.sidebar {
  position: fixed;
  top: 14%;
  left: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.sidebar ul {
  list-style: none;
  padding: 0;
}
.sidebar ul li {
  margin: 10px 0;
}
.sidebar ul li a {
  text-decoration: none;
  font-size: 30px;
  transition: color 0.3s ease; 
}
.sidebar ul li a:hover {
  color: #6a9b8f; 
}

/* FOOTER */
footer {
  display: flex;
  /* padding-bottom: 1em; */
  width: 80%;
  margin: 0 auto;
  padding: 3em 0 1em 0;
}
.footer-section {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
}
.yoursTruly {
  width: 80%;
}
.footer-section-2 h3, .footer-section-3 h3 {
  color: #6a9b8f;
}
.footer-section-1 {
  flex: 5;
  align-items: baseline;
}
.footer-section-2 {
  flex: 2;
}
.footer-section-3 {
  flex: 2;
}
.footer-section ul {
  list-style: none;
  padding: 5px;
  text-align: center;
}
.footer-section ul li {
  padding: 3px;
}
.footer-section ul li a:hover {
  color: #6a9b8f;
}
.footer-divider {
  border-top: 1.5px solid hsla(50,3%,47%,.5);
  width: 80%;
  margin: 20px auto;
}

/* HOME PAGE */
.introCover {
  margin: 10vh 0;
  position: relative;
}
.intro-text {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.intro-text h1 {
  font-size: 5vw; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.highlightIntro {
  font-weight: 500;
  color: #6a9b8f; 
}
.highlightIntro2 {
  animation: fadeIn 3s ease-in-out 1; 
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

/* PROJECTS */
.project-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: space-between;
  justify-items: center;
}
.proj, .proj-contain {
  position: relative;
  width: 100%;
  padding-top: 76%;
  border-radius: 15px;
  overflow: hidden;
}
.proj img, .proj-contain img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s ease-in-out;
}
.proj:hover img, .proj-contain:hover img, .project-contain:hover, .projects-box .proj-pic:hover img, .botImg:hover {
  transform: scale(1.03);
}
.proj:hover .card-overlay {
  opacity: 0.9;
}
.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1; 
}
.card-overlay p {
  font-size: 20px;
}

/* ABOUT PAGE */
.top {
  display: flex;
}
.about .left {
  flex: 2;
}
.about .right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-contain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-contain img {
  transition: all .3s ease-in-out;
  max-width: 50%;
  padding-bottom: 1em;
  border-radius: 25%;
}
.img-contain:hover img {
  transform: scale(1.03);
}
.hobbies-contain {
  display: flex;
  justify-content: center; 
  gap: 10px; 
  margin-bottom: 5rem;
}
.hob {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.hob img {
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out;
  z-index: 0;
  border-radius: 20px;
}
.hob:hover img {
  transform: scale(1.03);
}
.hob::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.9); 
  transition: background-color 0.7s ease; 
  z-index: 1;
  border-radius: 15px;
}
.hob:hover::before {
  background-color: transparent; 
}
.hob::after {
  content: ""; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  color: white;
  font-size: 50px;
  z-index: 2;
}
.blur-image {
  width: 100%;
  height: auto;
  filter: blur(10px);
  transition: filter 0.3s ease-in-out;
}
.images-loaded .blur-image {
  filter: blur(0);
}
#foodie::after {
  content: "food";
}
#cafe::after {
  content: "cafes";
}
#travel::after {
  content: "travel";
}
#foodie:hover::after, #cafe:hover::after, #travel:hover::after {
  color: white;
}
#foodie:hover::after, #cafe:hover::after, #travel:hover::after {
  content: none;
}

/* PROJECTS PAGE */
.projects-box .right {
  display: flex;
  justify-content: flex-end;
}
.projects-box .flex {
  gap: 4rem;
}
.projects-box .proj-pic img { /* Not sure how to zoom in */
  max-height: 23em;
  border-radius: 15px;
  margin-bottom: 1em;
  transition: transform .3s ease-in-out; 
  will-change: transform;
}
.project-contain {
  background-color: #4f6d7a;
  color: white;
  border-radius: 2rem;
  box-shadow: 0 10px 30px -10px rgba(0,0,0,.2); 
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 40rem; 
  padding: 2rem; 
  transition: transform .3s ease-in-out; 
  will-change: transform;
  margin-bottom: 1em;
}
.project-contain h4 {
  margin: 10px 0;
}
#noProj {
  color: red;
  text-align: center;
}

/* INDIVIDUAL PROJ PAGE */
.construction {
  color: white;
  background-color: #e49c0d81;
  border-radius: 5px;
  text-align: center;
  padding: 4px 0;
  margin: 4px 0;
}
.back-button {
  font-size: 30px;
  color: #000;
  text-decoration: none; 
  background-color: #e4e4e46d;
  padding: 1px 5px 0px 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Adds a transition effect */
}
.back-button:hover {
  background-color: #6a9b8f;
}
.proj-content-container h2 {
  color: #6a9b8f;
}
.link-container {
  margin-bottom: 1em;
}
.link-container a {
  color:rgb(74, 164, 209);
  font-weight: 900;
}
.projects-box .flex .right .proj-contain {
  max-width: 500px;
}
.projects-box .flex .right .proj-contain img {
  max-height: 300px;
}

/* MIDDLE */
.overview {
  display: flex;
  /* justify-content: center; */
}
.overview-container {
  display: flex;
  /* justify-content: space-around; */
  width: 100%;
  max-width: 800px;
}
.overview-left {
  width: 20%;
  padding-right: 10px;
}
.overview-right {
  width: 80%;
  padding-left: 10px;
}
.overview-heading {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 30%;
  box-sizing: border-box;
}
.overview-heading h2 {
  color: #6a9b8f;
}
.contri {
  background-color: rgba(233, 233, 233);
  padding: 15px 4%;
  border-radius: 15px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.contri:hover {
  transform: scale(1.03);
}
.box-hover {
  border-radius: 15px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.box-hover:hover {
  transform: scale(1.03);
}
.box-hover p:not(.card-overlay p),
.box-hover .botImg {
  transition: none !important;
  transform: none;
}
.box-hover p:not(.card-overlay p):hover {
  transform: none !important;
}
.op-margin {
  margin: 1em 0;
  font-weight: 300;
}
.descr {
  width: 75%;
}
.proj-content-container {
  display: flex;
  flex-direction:column;
  gap: 2rem;
  width: 100%; /* Ensure the container takes full width */
}
.proj-content-container .projPics {
  display: flex;
  justify-content: center; 
  gap: 10px; 
  margin-bottom: 1em;
  /* overflow-x: auto; */
}
.proj-content-container .projPics img {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensure image doesn't exceed container width */
  object-fit: contain; /* This will maintain aspect ratio */
  transition: all .3s ease-in-out;
  border-radius: 20px;
}
.proj-content-container {
  flex: 2;
}
.tuned {
  padding: 7em 0 4em 0;
  flex: 2;
}
.section-wrapper {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 2rem; */
}
.section-heading {
  margin-bottom: 1rem;
}
.section-content {
  width: 100%;
}
.section-wrapper p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.proj-content-container .projPics,
.proj-content-container {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* margin-top: 1em; */
}
.proj-content-container .projPics img,
.subsection-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all .3s ease-in-out;
  z-index: 0;
  border-radius: 20px;
}
.subsection h3 {
  color: rgb(74, 164, 209);
  font-weight: 500;
}
/* Cornell note style for larger screens */
@media screen and (min-width: 1300px) {
  .section-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }

  .section-heading {
    width: 15%;
    padding-right: 2rem;
    margin-bottom: 0;
  }

  .section-content {
    width: 80%;
  }

  .descr {
    width: 80%;
  }
}

@media screen and (max-width: 1299px) {
  .descr {
    width: 100%;
  }
}
.custom-link {
  display: inline-block;
  padding: 4px 8px;
  background-color: #6a9b8f; 
  color: rgb(232, 232, 232) !important; 
  border-radius: 8px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.custom-link:hover,
.custom-link:active {
  background-color: #507d71; /* A slightly darker shade for hover effect */
  text-decoration: none;
}
.custom-links-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media screen and (max-width: 1000px) {
  .intro-text h1 {
    font-size: 6.5vw;
  }
  .right {
      margin: 2em 0;
  }
  .flex {
    display: block;
      padding: 0;
  }
  .overview-parts {
      width: 100%;
  }
  /* FIX THIS P */
  .overview-parts p {
      padding: 0;
      text-align: center;
      font-size: 15px;
  }
  .final {
      width: 100%;
  }
  .projects-box, .hobbies-contain {
      margin-bottom: 3rem;
  }
  .proj-content-container .left-proj {
      margin-bottom: 20px;
  }
  .proj-content-container {
      display: block;
  }
  .projects-box .flex {
      padding-bottom: 0;
  }
  .projects-box .flex .right{
      justify-content: center;
  }
  .final p {
      margin-bottom: 0;
  }
  .project-card-grid {
      display: block;
  }
  .proj {
      margin-top: 1em;
  }
  #foodie::after, #cafe::after, #travel::after {
      font-size: 2em;
  }
  .top {
      display: block;
  }
  .sidebar ul li {
    margin: 20px 0;
  }
  .sidebar ul li a {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .divider {
      padding-top: 2em;
      padding-bottom: 1rem;
  }
  .flex {
      display: block;
  }
  h2 {
      font-size: 22px;
  }
  .dividerHr {
      height: 60px;
  }
  .custom-logo {
      width: 30px;
      height: auto;
  }
  .sidebar {
    display: none;
  }
  .navbar .nav-container ul {
      font-size: 13px;
  }
  .custom-logo {
      margin-right: 2em;
  }
  .theme-toggle-button {
      margin-left: 0;
  }
  footer {
      padding: 0;
  }
  .footer-section-1 {
      flex: 3;
      font-size: 8px;
  }
  .footer-section-2, .footer-section-3 {
      flex: 2;
      font-size: 11px;
  }
  .footer-divider {
      margin: 0 auto;
  }
  .introCover {
      margin: 20vh 0;
  }
  /* FIX THIS P */
  .project-card-grid .proj p {
      font-size: 12px;
  }
  .home p, .about p, .projects-box p, .proj-content-container, #stay-tuned {
      font-size: 15px;
  }
  .contri {
    margin-bottom: 1em;
    padding: 15px 7%;
  }
  .hobbies-contain {
      display: block;
  }
  .hob {
      width: 80%;
      height: auto;
      margin: 0 auto 1em;
  }
  .proj-content-container .projPics {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .projects-box .proj-pic img {
    max-height: 20em;
  }
  .proj-content-container .projPics img {
    max-width: 100%;
  }
  #rightImg {
      margin-top: 20px;
  }
}



